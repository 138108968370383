<template>
  <v-dialog 
    v-model="toggle" 
    transition="slide-y-reverse-transition"
    scrollable 
    fullscreen
  >
    <v-card 
      id="profile"
      class="sheet"
      dark
    >
      <v-toolbar
        dark
        :elevation="toolbarElevation"
        class="page-header flex-grow-0 flex-shrink-1"
      >
        <v-btn
          icon
          dark
          @click="close"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title class="title">Perfil</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            @click.stop="toggleHelp(true, 'profile')"
            icon
            class="mr-n2"
          >
            <v-icon v-text="icons.mdiHelpCircleOutline" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text
        id="profile-scroll"
        v-scroll:#profile-scroll="onScroll"
        ref="content"
        class="content pa-2 pb-12 flex-grow-1 flex-shrink-0"
      >
        <div 
          class="profile-header text-center py-4"
        >
          <div class="profile-img mb-8">
            <v-avatar 
              v-show="!toggleUpload"
              size="96"
              class="avatar elevation-2"
            >
              <img
                v-if="fields.photo.value!=null&&fields.photo.value!=''"
                :src="fields.photo.value"
              >
              <icon-base
                v-else
                width="96"
                height="96"
                name="avatar"
                class="avatar-default"
              >
                <avatar-default />
              </icon-base>
              <v-fab-transition>
                <v-btn
                  v-show="!toggleUpload"
                  class="btn-upload"
                  color="grey darken-3"
                  fab
                  dark
                  small 
                  absolute
                  bottom
                  right
                  elevation="2"
                  @click="toggleCropper(true);"
                >
                  <v-icon>{{ icons.mdiPencil }}</v-icon>
                </v-btn>
              </v-fab-transition>
            </v-avatar>

            <v-fab-transition>
              <v-btn
                v-show="toggleUpload"
                class="btn-cancel"
                fab
                light
                small
                absolute
                bottom
                left
                elevation="1"
                @click="toggleCropper(false);"
              >
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-fab-transition>

            <cropper
              v-show="toggleUpload"
              v-model="cropper"
              ref="cropper"
              :width="240"
              :height="240"
              :quality="2"
              :prevent-white-space="true"
              :zoom-speed="10"
              :disable-click-to-choose="true"
              :show-remove-button="false"
              :file-size-limit="10000000"
              accept="image/*" 
              capture="true"
              data-exif-orientation="1"
              placeholder=""
              @init="handleCropperInit"
              @new-image-drawn="handleNewImage"
              @file-type-mismatch="onFileTypeMismatch"
              @file-size-exceed="onFileSizeExceed"
            />

            <v-fab-transition>
              <v-btn
                v-show="toggleUpload"
                class="btn-ok darken-1"
                color="primary"
                fab
                light
                small
                absolute
                bottom
                right
                elevation="3"
                @click="prepareImgUpload();"
              >
                <v-icon>{{ icons.mdiCheck }}</v-icon>
              </v-btn>
            </v-fab-transition>
          </div>

          <div class="title text-h6 mt-4">
            {{ shortName }}
          </div>

          <m-field
            v-if="!signup&&!!profile.badges"
            outlined
            center
            dark
            label="Medalhas"
            :hint="badge_view.mini ? 'Toque para ver todas as medalhas' : null"
            class="badges-container mt-10 mx-4 rounded-lg"
          >
            <badges 
              :items="profile.badges.items"
              :mini="badge_view.mini"
              class="badges"
              @click="toggleBadges"
            />
            
          </m-field>

          <v-btn
            outlined
            small
            color="grey"
            class="mt-4"
            @click="toggleView({ view: 'profile', subview: 'referrals' })"
          >
            Indicações
          </v-btn>

          <!-- <v-card 
            v-if="profile.club_id!=null"
            outlined
            class="d-inline-flex align-center px-2 text-overline secondary--text mt-4"
            @click="club_id.toggle=true; selectText($refs['club_id'], true, 'ID Clube Mobees copiado!')"
          >
            ID Clube Mobees: 
            <span
              v-show="club_id.toggle"
              ref="club_id"
              class="font-weight-bold"
              style="text-transform: initial;"
            >
              {{ profile.club_id }}
            </span>
            <v-icon 
              v-show="!club_id.toggle"
              color="secondary"
              small
              class="ml-1"
            >
              {{ icons.mdiEye }}
            </v-icon>
          </v-card> -->
        </div> 

        <v-form
          ref="form"
          class="profile-form pb-12"
        >
          <v-list 
            flat
            two-line
            color="transparent"
          >
            <v-divider />
            <v-list-group 
              v-model="sections.personal"
              ref="personal"
              :disabled="signup ? false : !recentAuth"
              :ripple="false"
              :class="[!sectionState.personal.completed&&sectionState.personal.required ? 'pending' : 'completed']"
              class="form-group"
              @click.native="toggleAuth(true, 'personal'); scrollToSection('personal')"
            >
              <template v-slot:appendIcon>
                <v-icon class="section-icon">
                  {{ sectionState.personal.icon }}
                </v-icon>
              </template>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Pessoal</v-list-item-title>
                  <v-list-item-subtitle>Dados pessoais, endereço, etc</v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <div class="form-section pa-4 pb-0">
                <v-text-field
                  v-if="fields.fullname.toggle"
                  v-model="fields.fullname.value"
                  :loading="fields.fullname.loading"
                  :disabled="fields.fullname.disabled"
                  :label="fields.fullname.label"
                  :hint="fields.fullname.hint"
                  :rules="fields.fullname.rules"
                  :success="fields.fullname.success"
                  :error="fields.fullname.error"
                  @click.stop
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('fullname')"
                />

                <v-text-field
                  v-if="fields.email.toggle"
                  v-model="fields.email.value"
                  :loading="fields.email.loading"
                  :disabled="fields.email.disabled"
                  :label="fields.email.label"
                  :hint="fields.email.hint"
                  :persitent-hint="auth.sms"
                  :rules="fields.email.rules"
                  :success="fields.email.success"
                  :error="fields.email.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('email')"
                />

                <v-text-field
                  v-if="fields.cpf.toggle"
                  v-model="fields.cpf.value"
                  v-mask="fields.cpf.mask"
                  :loading="fields.cpf.loading"
                  :disabled="fields.cpf.disabled"
                  :label="fields.cpf.label"
                  :hint="fields.cpf.hint"
                  :rules="fields.cpf.rules"
                  :success="fields.cpf.success"
                  :error="fields.cpf.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('cpf')"
                />

                <v-text-field
                  v-if="fields.rg.id.toggle"
                  v-model="fields.rg.id.value"
                  :loading="fields.rg.id.loading"
                  :disabled="fields.rg.id.disabled"
                  :label="fields.rg.id.label"
                  :rules="fields.rg.id.rules"
                  :success="fields.rg.id.success"
                  :error="fields.rg.id.error"
                  maxlength="11"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('rg.id')"
                />

                <v-text-field
                  v-if="fields.rg.issuer.toggle"
                  v-model="fields.rg.issuer.value"
                  :loading="fields.rg.issuer.loading"
                  :disabled="fields.rg.issuer.disabled"
                  :label="fields.rg.issuer.label"
                  :rules="fields.rg.issuer.rules"
                  :success="fields.rg.issuer.success"
                  :error="fields.rg.issuer.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('rg.issuer')"
                />

                <v-text-field
                  v-if="fields.cnh.toggle"
                  v-model="fields.cnh.value"
                  :loading="fields.cnh.loading"
                  :disabled="fields.cnh.disabled"
                  :label="fields.cnh.label"
                  :rules="fields.cnh.rules"
                  :success="fields.cnh.success"
                  :error="fields.cnh.error"
                  maxlength="11"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('cnh')"
                />

                <v-text-field
                  v-if="fields.phone.toggle"
                  v-model="fields.phone.value"
                  v-mask="fields.phone.mask"
                  :loading="fields.phone.loading"
                  :disabled="fields.phone.disabled"
                  :label="fields.phone.label"
                  :hint="fields.phone.hint"
                  :rules="fields.phone.rules"
                  :success="fields.phone.success"
                  :error="fields.phone.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('phone')"
                />

                <v-text-field
                  v-if="fields.birthdate.toggle"
                  v-model="fields.birthdate.value"
                  v-mask="fields.birthdate.mask"
                  :loading="fields.birthdate.loading"
                  :disabled="fields.birthdate.disabled"
                  :label="fields.birthdate.label"
                  :hint="fields.birthdate.hint"
                  :rules="fields.birthdate.rules"
                  :success="fields.birthdate.success"
                  :error="fields.birthdate.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('birthdate')"
                />

                <v-select
                  v-if="fields.gender.toggle"
                  v-model="fields.gender.value"
                  :items="fields.gender.items"
                  :loading="fields.gender.loading"
                  :disabled="fields.gender.disabled"
                  :label="fields.gender.label"
                  :rules="fields.gender.rules"
                  :success="fields.gender.success"
                  :error="fields.gender.error"
                  :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu secondary' }"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @change="profileChanged('gender')"
                />

                <v-text-field
                  v-if="fields.address.zip.toggle"
                  v-model="fields.address.zip.value"
                  v-mask="fields.address.zip.mask"
                  :loading="fields.address.zip.loading"
                  :disabled="fields.address.zip.disabled"
                  :label="fields.address.zip.label"
                  :hint="fields.address.zip.hint"
                  :rules="fields.address.zip.rules"
                  :success="fields.address.zip.success"
                  :error="fields.address.zip.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('address.zip')"
                />

                <v-text-field
                  v-if="fields.address.street.toggle"
                  v-model="fields.address.street.value"
                  :loading="fields.address.street.loading"
                  :disabled="fields.address.street.disabled"
                  :label="fields.address.street.label"
                  :hint="fields.address.street.hint"
                  :rules="fields.address.street.rules"
                  :success="fields.address.street.success"
                  :error="fields.address.street.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('address.street')"
                />

                <v-row>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      v-if="fields.address.number.toggle"
                      v-model="fields.address.number.value"
                      v-mask="fields.address.number.mask"
                      :loading="fields.address.number.loading"
                      :disabled="fields.address.number.disabled"
                      :label="fields.address.number.label"
                      :rules="fields.address.number.rules"
                      :success="fields.address.number.success"
                      :error="fields.address.number.error"
                      outlined
                      color="secondary"
                      class="control"
                      @focus="onFormFocus(true)"
                      @blur="onFormFocus(false)"
                      @input="profileChanged('address.number')"
                    />
                  </v-col>
                  <v-col class="py-0">
                    <v-text-field
                      v-if="fields.address.compl.toggle"
                      v-model="fields.address.compl.value"
                      :loading="fields.address.compl.loading"
                      :disabled="fields.address.compl.disabled"
                      :label="fields.address.compl.label"
                      :hint="fields.address.compl.hint"
                      :rules="fields.address.compl.rules"
                      :success="fields.address.compl.success"
                      :error="fields.address.compl.error"
                      outlined
                      color="secondary"
                      class="control"
                      @focus="onFormFocus(true)"
                      @blur="onFormFocus(false)"
                      @input="profileChanged('address.compl')"
                    />
                  </v-col>
                </v-row>

                <v-text-field
                  v-if="fields.address.neighborhood.toggle"
                  v-model="fields.address.neighborhood.value"
                  :loading="fields.address.neighborhood.loading"
                  :disabled="fields.address.neighborhood.disabled"
                  :label="fields.address.neighborhood.label"
                  :hint="fields.address.neighborhood.hint"
                  :rules="fields.address.neighborhood.rules"
                  :success="fields.address.neighborhood.success"
                  :error="fields.address.neighborhood.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('address.neighborhood')"
                />

                <v-text-field
                  v-if="fields.address.city.toggle"
                  v-model="fields.address.city.value"
                  :loading="fields.address.city.loading"
                  :disabled="fields.address.city.disabled"
                  :label="fields.address.city.label"
                  :hint="fields.address.city.hint"
                  :rules="fields.address.city.rules"
                  :success="fields.address.city.success"
                  :error="fields.address.city.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('address.city')"
                />

                <v-select
                  v-if="fields.address.state.toggle"
                  v-model="fields.address.state.value"
                  :items="fields.address.state.items"
                  :loading="fields.address.state.loading"
                  :disabled="fields.address.state.disabled"
                  :label="fields.address.state.label"
                  :hint="fields.address.state.hint"
                  :rules="fields.address.state.rules"
                  :success="fields.address.state.success"
                  :error="fields.address.state.error"
                  :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu secondary' }"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @change="profileChanged('address.state')"
                />

                <v-text-field
                  v-if="fields.address.country.toggle"
                  v-model="fields.address.country.value"
                  :loading="fields.address.country.loading"
                  :disabled="fields.address.country.disabled"
                  :label="fields.address.country.label"
                  :hint="fields.address.country.hint"
                  :rules="fields.address.country.rules"
                  :success="fields.address.country.success"
                  :error="fields.address.country.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('address.country')"
                />
              </div>
            </v-list-group>

            <v-divider />
            <v-list-group 
              v-model="sections.vehicle"
              ref="vehicle"
              :disabled="signup ? false : !recentAuth"
              :ripple="false"
              :class="[!sectionState.vehicle.completed&&sectionState.vehicle.required ? 'pending' : 'completed']"
              class="form-group"
              @click.native="toggleAuth(true, 'vehicle'); scrollToSection('vehicle')"
            >
              <template v-slot:appendIcon>
                <v-icon class="section-icon">
                  {{ sectionState.vehicle.icon }}
                </v-icon>
              </template>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Veículo</v-list-item-title>
                  <v-list-item-subtitle>Informações sobre seu carro</v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <div class="form-section pa-4 pb-0">
                <!-- <v-combobox
                  v-if="fields.vehicle.brand.toggle"
                  v-model="profile.vehicle.brand"
                  :items="fields.vehicle.brand.items"
                  :loading="fields.vehicle.brand.loading"
                  :disabled="fields.vehicle.brand.disabled"
                  :label="fields.vehicle.brand.label"
                  :hint="fields.vehicle.brand.hint"
                  @change="profileChanged('vehicle.brand')"
                  @input="profileChanged('vehicle.brand')"
                  outlined
                  color="secondary"
                  class="control"
                /> -->

                <v-select
                  v-if="fields.vehicle.model.toggle"
                  v-model="fields.vehicle.model.value"
                  :items="fields.vehicle.model.items"
                  :loading="fields.vehicle.model.loading"
                  :disabled="fields.vehicle.model.disabled"
                  :label="fields.vehicle.model.label"
                  :hint="fields.vehicle.model.hint"
                  :rules="fields.vehicle.model.rules"
                  :success="fields.vehicle.model.success"
                  :error="fields.vehicle.model.error"
                  :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu secondary' }"
                  single-line
                  persistent-hint
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('vehicle.model')"
                />
                <!-- <template v-slot:no-data>
                  <v-list-item @click="profile.vehicle.model='Outro'">
                    <v-list-item-content>
                      <v-list-item-title>
                        Outro
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template> -->

                <v-text-field
                  v-if="fields.vehicle.color.toggle"
                  v-model="fields.vehicle.color.value"
                  :loading="fields.vehicle.color.loading"
                  :disabled="fields.vehicle.color.disabled"
                  :label="fields.vehicle.color.label"
                  :hint="fields.vehicle.color.hint"
                  :rules="fields.vehicle.color.rules"
                  :success="fields.vehicle.color.success"
                  :error="fields.vehicle.color.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('vehicle.color')"
                />

                <m-select
                  v-if="fields.vehicle.fuel.toggle"
                  :value="fields.vehicle.fuel.value"
                  :options="fields.vehicle.fuel.items"
                  :loading="fields.vehicle.fuel.loading"
                  :disabled="fields.vehicle.fuel.disabled"
                  :label="fields.vehicle.fuel.label"
                  :hint="fields.vehicle.fuel.hint"
                  :rules="fields.vehicle.fuel.rules"
                  :success="fields.vehicle.fuel.success"
                  :error="fields.vehicle.fuel.error"
                  dark
                  multiple
                  outlined
                  vertical
                  color="secondary"
                  class="control"
                  @change="(value) => setField('vehicle.fuel', value)"
                />

                <v-text-field
                  v-if="fields.vehicle.plate.toggle"
                  v-model="fields.vehicle.plate.value"
                  :loading="fields.vehicle.plate.loading"
                  :disabled="fields.vehicle.plate.disabled"
                  :label="fields.vehicle.plate.label"
                  :hint="fields.vehicle.plate.hint"
                  :rules="fields.vehicle.plate.rules"
                  :success="fields.vehicle.plate.success"
                  :error="fields.vehicle.plate.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('vehicle.plate')"
                />

                <v-text-field
                  v-if="fields.vehicle.chassis.toggle"
                  v-model="fields.vehicle.chassis.value"
                  :loading="fields.vehicle.chassis.loading"
                  :disabled="fields.vehicle.chassis.disabled"
                  :label="fields.vehicle.chassis.label"
                  :hint="fields.vehicle.chassis.hint"
                  :rules="fields.vehicle.chassis.rules"
                  :success="fields.vehicle.chassis.success"
                  :error="fields.vehicle.chassis.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('vehicle.chassis')"
                />

                <v-text-field
                  v-if="fields.vehicle.renavam.toggle"
                  v-model="fields.vehicle.renavam.value"
                  :loading="fields.vehicle.renavam.loading"
                  :disabled="fields.vehicle.renavam.disabled"
                  :label="fields.vehicle.renavam.label"
                  :rules="fields.vehicle.renavam.rules"
                  :success="fields.vehicle.renavam.success"
                  :error="fields.vehicle.renavam.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('vehicle.renavam')"
                />

                <v-text-field
                  v-if="fields.vehicle.year.toggle"
                  v-model="fields.vehicle.year.value"
                  :loading="fields.vehicle.year.loading"
                  :disabled="fields.vehicle.year.disabled"
                  :label="fields.vehicle.year.label"
                  :hint="fields.vehicle.year.hint"
                  :rules="fields.vehicle.year.rules"
                  :success="fields.vehicle.year.success"
                  :error="fields.vehicle.year.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('vehicle.year')"
                />

                <v-select
                  v-if="fields.vehicle.parking.toggle"
                  v-model="fields.vehicle.parking.value"
                  :items="fields.vehicle.parking.items"
                  :loading="fields.vehicle.parking.loading"
                  :disabled="fields.vehicle.parking.disabled"
                  :label="fields.vehicle.parking.label"
                  :hint="fields.vehicle.parking.hint"
                  :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu secondary' }"
                  :rules="fields.vehicle.parking.rules"
                  :success="fields.vehicle.parking.success"
                  :error="fields.vehicle.parking.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @change="profileChanged('vehicle.parking')"
                />

                <v-select
                  v-if="fields.vehicle.isOwner.toggle"
                  v-model="fields.vehicle.isOwner.value"
                  :items="fields.vehicle.isOwner.items"
                  :loading="fields.vehicle.isOwner.loading"
                  :disabled="fields.vehicle.isOwner.disabled"
                  :label="fields.vehicle.isOwner.label"
                  :hint="fields.vehicle.isOwner.hint"
                  :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu secondary' }"
                  :rules="fields.vehicle.isOwner.rules"
                  :success="fields.vehicle.isOwner.success"
                  :error="fields.vehicle.isOwner.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @change="profileChanged('vehicle.isOwner'); updateRequiredFields();"
                />
                <!-- <v-list-item
                  v-if="fields.vehicle.isOwner.toggle"
                  class="mt-n2 px-3"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ fields.vehicle.isOwner.label }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch 
                      v-model="profile.vehicle.isOwner" 
                      :loading="fields.vehicle.isOwner.loading"
                      :disabled="fields.vehicle.isOwner.disabled"
                      @change="profileChanged('vehicle.isOwner'); updateRequiredFields('vehicle', 'ownerRelative', !profile.vehicle.isOwner)"
                      inset
                      color="secondary"
                      class="control"
                    />
                  </v-list-item-action>
                </v-list-item> -->

                <v-expand-transition>
                  <div
                    v-if="profile.vehicle.isOwner==2"
                    class="other-owner"
                  >
                    <v-text-field
                      v-if="fields.vehicle.rental.toggle"
                      v-model="fields.vehicle.rental.value"
                      :loading="fields.vehicle.rental.loading"
                      :disabled="fields.vehicle.rental.disabled"
                      :label="fields.vehicle.rental.label"
                      :rules="fields.vehicle.rental.rules"
                      :success="fields.vehicle.rental.success"
                      :error="fields.vehicle.rental.error"
                      outlined
                      color="secondary"
                      class="control"
                      @focus="onFormFocus(true)"
                      @blur="onFormFocus(false)"
                      @input="profileChanged('vehicle.rental')"
                    />
                  </div>
                  <div
                    v-else-if="profile.vehicle.isOwner==0"
                    class="other-owner"
                  >
                    <v-text-field
                      v-if="fields.vehicle.ownerName.toggle"
                      v-model="fields.vehicle.ownerName.value"
                      :loading="fields.vehicle.ownerName.loading"
                      :disabled="fields.vehicle.ownerName.disabled"
                      :label="fields.vehicle.ownerName.label"
                      :rules="fields.vehicle.ownerName.rules"
                      :success="fields.vehicle.ownerName.success"
                      :error="fields.vehicle.ownerName.error"
                      outlined
                      color="secondary"
                      class="control"
                      @focus="onFormFocus(true)"
                      @blur="onFormFocus(false)"
                      @input="profileChanged('vehicle.ownerName')"
                    />
                    <v-text-field
                      v-if="fields.vehicle.ownerEmail.toggle"
                      v-model="fields.vehicle.ownerEmail.value"
                      :loading="fields.vehicle.ownerEmail.loading"
                      :disabled="fields.vehicle.ownerEmail.disabled"
                      :label="fields.vehicle.ownerEmail.label"
                      :hint="fields.vehicle.ownerEmail.hint"
                      :rules="fields.vehicle.ownerEmail.rules"
                      :success="fields.vehicle.ownerEmail.success"
                      :error="fields.vehicle.ownerEmail.error"
                      outlined
                      color="secondary"
                      class="control"
                      @focus="onFormFocus(true)"
                      @blur="onFormFocus(false)"
                      @input="profileChanged('vehicle.ownerEmail')"
                    />
                    <v-text-field
                      v-if="fields.vehicle.ownerRg.toggle"
                      v-model="fields.vehicle.ownerRg.value"
                      :loading="fields.vehicle.ownerRg.loading"
                      :disabled="fields.vehicle.ownerRg.disabled"
                      :label="fields.vehicle.ownerRg.label"
                      :rules="fields.vehicle.ownerRg.rules"
                      :success="fields.vehicle.ownerRg.success"
                      :error="fields.vehicle.ownerRg.error"
                      outlined
                      color="secondary"
                      class="control"
                      @focus="onFormFocus(true)"
                      @blur="onFormFocus(false)"
                      @input="profileChanged('vehicle.ownerRg')"
                    />
                    <v-text-field
                      v-if="fields.vehicle.ownerOrg_exp.toggle"
                      v-model="fields.vehicle.ownerOrg_exp.value"
                      :loading="fields.vehicle.ownerOrg_exp.loading"
                      :disabled="fields.vehicle.ownerOrg_exp.disabled"
                      :label="fields.vehicle.ownerOrg_exp.label"
                      :rules="fields.vehicle.ownerOrg_exp.rules"
                      :success="fields.vehicle.ownerOrg_exp.success"
                      :error="fields.vehicle.ownerOrg_exp.error"
                      outlined
                      color="secondary"
                      class="control"
                      @focus="onFormFocus(true)"
                      @blur="onFormFocus(false)"
                      @input="profileChanged('vehicle.ownerOrg_exp')"
                    />
                    <v-text-field
                      v-if="fields.vehicle.ownerCpf.toggle"
                      v-model="fields.vehicle.ownerCpf.value"
                      v-mask="fields.vehicle.ownerCpf.mask"
                      :loading="fields.vehicle.ownerCpf.loading"
                      :disabled="fields.vehicle.ownerCpf.disabled"
                      :label="fields.vehicle.ownerCpf.label"
                      :rules="fields.vehicle.ownerCpf.rules"
                      :success="fields.vehicle.ownerCpf.success"
                      :error="fields.vehicle.ownerCpf.error"
                      outlined
                      color="secondary"
                      class="control"
                      @focus="onFormFocus(true)"
                      @blur="onFormFocus(false)"
                      @input="profileChanged('vehicle.ownerCpf')"
                    />
                    <v-text-field
                      v-if="fields.vehicle.ownerRelative.toggle"
                      v-model="fields.vehicle.ownerRelative.value"
                      :loading="fields.vehicle.ownerRelative.loading"
                      :disabled="fields.vehicle.ownerRelative.disabled"
                      :label="fields.vehicle.ownerRelative.label"
                      :rules="fields.vehicle.ownerRelative.rules"
                      :success="fields.vehicle.ownerRelative.success"
                      :error="fields.vehicle.ownerRelative.error"
                      outlined
                      color="secondary"
                      class="control"
                      @focus="onFormFocus(true)"
                      @blur="onFormFocus(false)"
                      @input="profileChanged('vehicle.ownerRelative')"
                    />
                  </div>
                </v-expand-transition>
              </div>
            </v-list-group>

            <v-divider />
            <v-list-group 
              v-model="sections.job"
              ref="job"
              :disabled="signup ? false : !recentAuth"
              :ripple="false"
              :class="[!sectionState.job.completed&&sectionState.job.required ? 'pending' : 'completed']"
              class="form-group"
              @click.native="toggleAuth(true, 'job'); scrollToSection('job')"
            >
              <template v-slot:appendIcon>
                <v-icon class="section-icon">
                  {{ sectionState.job.icon }}
                </v-icon>
              </template>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Profissional</v-list-item-title>
                  <v-list-item-subtitle>Dados sobre sua rotina de trabalho</v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <div class="form-section pa-4 pb-0">
                <m-select
                  v-if="fields.job.products.toggle"
                  :value="fields.job.products.value"
                  :options="fields.job.products.items"
                  :loading="fields.job.products.loading"
                  :disabled="fields.job.products.disabled"
                  :label="fields.job.products.label"
                  :hint="fields.job.products.hint"
                  :rules="fields.job.products.rules"
                  :success="fields.job.products.success"
                  :error="fields.job.special_projects.error"
                  dark
                  multiple
                  outlined
                  vertical
                  color="secondary"
                  class="control"
                  @change="(value) => setField('job.products', value)"
                />
                <v-select
                  v-if="fields.job.special_projects.toggle"
                  v-model="fields.job.special_projects.value"
                  :items="fields.job.special_projects.items"
                  :loading="fields.job.special_projects.loading"
                  :disabled="fields.job.special_projects.disabled"
                  :label="fields.job.special_projects.label"
                  :placeholder="fields.job.special_projects.placeholder"
                  :rules="fields.job.special_projects.rules"
                  :success="fields.job.special_projects.success"
                  :error="fields.job.special_projects.error"
                  :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu secondary' }"
                  :append-icon="icons.mdiHelpCircleOutline"
                  outlined
                  color="secondary"
                  class="control fixed-icon"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @click:append.stop="toggleHelp"
                  @change="profileChanged('job.special_projects')"
                />
                <!-- <a class="hint text-caption" @click="toggleHelp">
                  {{ fields.job.special_projects.hint }}
                </a> -->
                <m-select
                  v-if="fields.job.apps.toggle"
                  :value="fields.job.apps.value"
                  :options="fields.job.apps.items"
                  :loading="fields.job.apps.loading"
                  :disabled="fields.job.apps.disabled"
                  :label="fields.job.apps.label"
                  :hint="fields.job.apps.hint"
                  :rules="fields.job.apps.rules"
                  :success="fields.job.apps.success"
                  :error="fields.job.apps.error"
                  dark
                  multiple
                  outlined
                  vertical
                  color="secondary"
                  class="control"
                  @change="(value) => setField('job.apps', value)"
                />
                <v-expand-transition>
                  <m-field
                    v-if="fields.job.apps_percent.toggle&&!!fields.job.apps.value&&fields.job.apps.value.length>1"
                    :value="fields.job.apps_percent.value"
                    :loading="fields.job.apps_percent.loading"
                    :disabled="fields.job.apps_percent.disabled"
                    :label="fields.job.apps_percent.label"
                    :hint="fields.job.apps_percent.hint"
                    :rules="fields.job.apps_percent.rules"
                    :success="fields.job.apps_percent.success"
                    :error="fields.job.apps_percent.error"
                    dark
                    color="secondary"
                    class="apps-percent control"
                  >
                    <div
                      v-for="selected in fields.job.apps_percent.value"
                      :key="'app-percent-'+selected.app"
                      class="app-percent d-flex align-center pl-4"
                    >
                      <span class="white--text text-body-1 mr-2">
                        {{ selected.text }}:
                      </span>
                      <!-- <v-spacer /> -->
                      <v-select
                        v-model="selected.value"
                        :items="fields.job.apps_percent.items"
                        :placeholder="fields.job.apps_percent.placeholder"
                        :success="fields.job.apps_percent.success"
                        :error="fields.job.apps_percent.error"
                        :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu secondary' }"
                        solo
                        flat
                        hide-details
                        color="secondary"
                        class="transparent"
                        @change="updateAppsPercent(true, selected)"
                      />
                    </div>
                  </m-field>
                </v-expand-transition>
                <v-select
                  v-if="fields.job.service_model.toggle"
                  v-model="fields.job.service_model.value"
                  :items="fields.job.service_model.items"
                  :loading="fields.job.service_model.loading"
                  :disabled="fields.job.service_model.disabled"
                  :label="fields.job.service_model.label"
                  :placeholder="fields.job.apps.placeholder"
                  :hint="fields.job.service_model.hint"
                  :rules="fields.job.service_model.rules"
                  :success="fields.job.service_model.success"
                  :error="fields.job.service_model.error"
                  :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu secondary' }"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @change="profileChanged('job.service_model')"
                >
                  <template v-slot:item="data">
                    <v-list-item 
                      v-bind="data.attrs"
                      v-on="data.on"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.text }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ data.item.subtitle }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>

                <v-select
                  v-if="fields.job.fulltime.toggle"
                  v-model="fields.job.fulltime.value"
                  :items="fields.job.fulltime.items"
                  :loading="fields.job.fulltime.loading"
                  :disabled="fields.job.fulltime.disabled"
                  :label="fields.job.fulltime.label"
                  :placeholder="fields.job.fulltime.placeholder"
                  :hint="fields.job.fulltime.hint"
                  :rules="fields.job.fulltime.rules"
                  :success="fields.job.fulltime.success"
                  :error="fields.job.fulltime.error"
                  persistent-hint
                  :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu secondary' }"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @change="profileChanged('job.fulltime')"
                />

                <v-select
                  v-if="fields.job.journey.toggle"
                  v-model="fields.job.journey.value"
                  :items="fields.job.journey.items"
                  :loading="fields.job.journey.loading"
                  :disabled="fields.job.journey.disabled"
                  :label="fields.job.journey.label"
                  :placeholder="fields.job.journey.placeholder"
                  :hint="fields.job.journey.hint"
                  persistent-hint
                  :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu secondary' }"
                  :rules="fields.job.journey.rules"
                  :success="fields.job.journey.success"
                  :error="fields.job.journey.error"
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @change="profileChanged('job.journey')"
                />

                <v-select
                  v-if="fields.job.region.toggle"
                  v-model="fields.job.region.value"
                  :items="fields.job.region.items"
                  :filter="regionFilter"
                  :loading="fields.job.region.loading"
                  :disabled="fields.job.region.disabled"
                  :label="fields.job.region.label"
                  :hint="fields.job.region.hint"
                  :rules="fields.job.region.rules"
                  :success="fields.job.region.success"
                  :error="fields.job.region.error"
                  :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu secondary' }"
                  hide-no-data
                  outlined
                  color="secondary"
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @change="profileChanged('job.region')"
                />

                <v-text-field
                  v-if="fields.job.rating.toggle"
                  v-model="fields.job.rating.value"
                  v-mask="fields.job.rating.mask"
                  :loading="fields.job.rating.loading"
                  :disabled="fields.job.rating.disabled"
                  :label="fields.job.rating.label"
                  :hint="fields.job.rating.hint"
                  :rules="fields.job.rating.rules"
                  :success="fields.job.rating.success"
                  :error="fields.job.rating.error"
                  type="tel"
                  color="secondary"
                  outlined
                  class="control"
                  @focus="onFormFocus(true)"
                  @blur="onFormFocus(false)"
                  @input="profileChanged('job.rating')"
                />
                
                <v-subheader class="px-0 mt-n4 caption">
                  {{ fields.job.experience.label }}
                </v-subheader>
                <v-slider
                  v-if="fields.job.experience.toggle"
                  v-model="fields.job.experience.value"
                  :disabled="fields.job.experience.disabled"
                  :hint="fields.job.experience.hint"
                  :success="fields.job.experience.success"
                  :error="fields.job.experience.error"
                  thumb-label="always"
                  :max="fields.job.experience.max"
                  min="0"
                  color="secondary"
                  outlined
                  class="control mb-4"
                  @input="profileChanged('job.experience'); roundExperience();"
                >
                  <template v-slot:thumb-label="{ value }">
                    <v-progress-circular 
                      v-if="fields.job.experience.loading"
                      size="16"
                      width="2"
                      indeterminate
                      color="white"
                    />
                    <v-icon 
                      v-else
                      small
                    >
                      {{ icons.mdiArrowLeftRight }}
                    </v-icon>
                  </template>
                  <template v-slot:prepend>
                    <span
                      class="body-1"
                      style="min-width: 80px; white-space: nowrap;"
                    >
                      {{ fields.job.experience.value | humanizeExperience }}
                    </span>
                  </template>
                </v-slider>
              </div>
            </v-list-group>

            <v-divider v-if="status!='ACC'&&status!='FDB'" />
            <v-list-group 
              v-if="status!='ACC'&&status!='FDB'"
              v-model="sections.payment"
              ref="payment"
              :disabled="signup ? false : !recentAuth"
              :ripple="false"
              :class="[!sectionState.payment.completed&&sectionState.payment.required ? 'pending' : 'completed']"
              class="form-group"
              @click.native="toggleAuth(true, 'payment'); scrollToSection('payment')"
            >
              <template v-slot:appendIcon>
                <v-icon class="section-icon">
                  {{ sectionState.payment.icon }}
                </v-icon>
              </template>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Financeiro</v-list-item-title>
                  <v-list-item-subtitle>Dados bancários para pagamento</v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <div class="form-section pa-4 pb-0">
                <v-autocomplete
                  v-model="fields.payment.bank.value"
                  :items="bankList"
                  :loading="fields.payment.bank.loading"
                  :disabled="fields.payment.bank.disabled"
                  :label="fields.payment.bank.label"
                  :hint="fields.payment.bank.hint"
                  :rules="fields.payment.bank.rules"
                  :success="fields.payment.bank.success"
                  :error="fields.payment.bank.error"
                  :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu secondary' }"
                  hide-no-data
                  hide-selected
                  item-text="title"
                  item-value="id"
                  outlined
                  color="secondary"
                  @change="profileChanged('payment.bank')"
                  class="control"
                />

                <v-text-field
                  v-model="fields.payment.branch.value"
                  :loading="fields.payment.branch.loading"
                  :disabled="fields.payment.branch.disabled"
                  :label="fields.payment.branch.label"
                  :hint="fields.payment.branch.hint"
                  :rules="fields.payment.branch.rules"
                  :success="fields.payment.branch.success"
                  :error="fields.payment.branch.error"
                  outlined
                  color="secondary"
                  class="control"
                  @keyup="profileChanged('payment.branch')"
                />

                <v-row class="my-0">
                  <v-col cols="7" class="py-0">
                    <v-text-field
                      v-model="fields.payment.account.value"
                      :loading="fields.payment.account.loading"
                      :disabled="fields.payment.account.disabled"
                      :label="fields.payment.account.label"
                      :hint="fields.payment.account.hint"
                      :rules="fields.payment.account.rules"
                      :success="fields.payment.account.success"
                      :error="fields.payment.account.error"
                      outlined
                      color="secondary"
                      class="control"
                      @keyup="profileChanged('payment.account')"
                    />
                  </v-col>
                  <v-col class="py-0">
                    <v-text-field
                      v-model="fields.payment.digit.value"
                      :loading="fields.payment.digit.loading"
                      :disabled="fields.payment.digit.disabled"
                      :label="fields.payment.digit.label"
                      :hint="fields.payment.digit.hint"
                      :rules="fields.payment.digit.rules"
                      :success="fields.payment.digit.success"
                      :error="fields.payment.digit.error"
                      outlined
                      color="secondary"
                      class="control"
                      @keyup="profileChanged('payment.digit')"
                    />
                  </v-col>
                </v-row>

                <v-select
                  v-model="fields.payment.type.value"
                  :items="fields.payment.type.items"
                  :loading="fields.payment.type.loading"
                  :disabled="fields.payment.type.disabled"
                  :label="fields.payment.type.label"
                  :hint="fields.payment.type.hint"
                  :rules="fields.payment.type.rules"
                  :success="fields.payment.type.success"
                  :error="fields.payment.type.error"
                  :menu-props="{ bottom: true, offsetY: true, 'content-class': 'profile-menu secondary' }"
                  outlined
                  color="secondary"
                  class="control"
                  @change="profileChanged('payment.type')"
                />

                <v-text-field
                  v-model="fields.payment.compl.value"
                  :loading="fields.payment.compl.loading"
                  :disabled="fields.payment.compl.disabled"
                  :label="fields.payment.compl.label"
                  :hint="fields.payment.compl.hint"
                  :rules="fields.payment.compl.rules"
                  :success="fields.payment.compl.success"
                  :error="fields.payment.compl.error"
                  outlined
                  color="secondary"
                  class="control"
                  @keyup="profileChanged('payment.compl')"
                />
              </div>
            </v-list-group>

            <v-divider />
            <v-list-group 
              ref="account"
              :ripple="false"
              class="form-group"
              @click="scrollToSection('account')"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Opções da Conta</v-list-item-title>
                  <v-list-item-subtitle>Configurações gerais da sua conta</v-list-item-subtitle>
                </v-list-item-content>
              </template>
              
              <div class="form-section pa-0 pb-4">
                <v-list-item
                  v-if="signup&&installed"
                  color="secondary"
                >
                  <v-list-item-action>
                    <v-checkbox 
                      v-model="profile.gps"
                      :loading="fields.gps.loading"
                      :readonly="fields.gps.disabled"
                      color="primary"
                      class="control"
                      @click="requestGpsPermission()"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ fields.gps.label }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="installed"
                  color="secondary"
                >
                  <v-list-item-action>
                    <v-checkbox 
                      v-model="profile.push"
                      :loading="fields.push.loading"
                      :readonly="fields.push.disabled"
                      color="primary"
                      class="control"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ fields.push.label }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset />
                
                <v-list-item
                  color="secondary"
                  @click="password.toggle = true;"
                >
                  <v-list-item-action>
                    <v-btn
                      icon
                      small
                    >
                      <v-icon>{{ icons.mdiPencilLock }}</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Alterar minha senha</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  color="secondary"
                  @click="logout(rawData(cpf), auth.token, ghost); logoutBtn.loading = true;"
                >
                  <v-list-item-action>
                    <v-btn
                      icon
                      small
                      :loading="logoutBtn.loading"
                    >
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Sair do app</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset />

                <v-list-item
                  v-if="signup"
                  @click="dialogs.deleteAccount.toggle = true;"
                >
                  <v-list-item-action>
                    <v-btn
                      icon
                      small
                      class="error--text text--darken-2"
                    >
                      <v-icon>{{ icons.mdiDelete }}</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="error--text text--darken-2">
                      Apagar minha conta
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                
                <!-- <v-btn 
                  v-if="!recentAuth && !signup"
                  text 
                  outlined
                  block
                  large
                  class="my-8"
                  @click="dialogs.auth.toggle = true;"
                >
                  Alterar meus dados
                </v-btn> 

                <v-btn 
                  text 
                  class="my-8"
                  @click="password.toggle = true;"
                >
                  Alterar minha senha
                </v-btn>
                
                <v-btn 
                  text 
                  class="my-4"
                  @click="logout(rawData(cpf),auth.token)"
                >
                  Logout
                </v-btn>  
                -->
              </div>
            </v-list-group>

            <!-- <v-divider /> -->
          </v-list>
        </v-form>
      </v-card-text>
      <v-fab-transition>
        <v-btn
          v-show="saveable&&!signup"
          color="primary"
          class="btn-save darken-1"
          dark
          absolute
          bottom
          right
          fab
          :loading="saveBtn.loading"
          :disabled="saveBtn.disabled"
          @click="updateProfile"
        >
          <v-icon>{{ icons.mdiCheck }}</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>

    <v-scroll-y-transition>
      <v-sheet
        v-if="user.status=='ACC'&&(!keyboard||stepCompletion==100)"
        color="transparent"
        elevation="0"
        class="profile-footer d-flex flex-column"
        :class="{ 'no-events': stepCompletion!=100 }"
      >
        <v-btn 
          v-if="stepCompletion==100"
          large
          color="accent"
          class="btn-cta grey--text text--darken-4 ma-4"
          :loading="sendBtn.loading||requestEvaluationLoading"
          @click="requestEvaluation()"
        >
          Enviar meus dados
        </v-btn>
        <completion-progress
          v-else-if="stepCompletion!=null"
          :completion="stepCompletion"
          class="profile-completion pa-8"
        />
      </v-sheet>
    </v-scroll-y-transition>

    <v-dialog
      v-model="dialogs.deleteAccount.toggle"
      overlay-opacity=".8"
      transition="slide-y-reverse-transition"
    >
      <v-card color="black">
        <v-card-title>Apagar sua conta?</v-card-title>
        <v-card-text class="py-4">
          <p class="body-2">
            Para apagar sua conta na Mobees, digite <b>apagar</b> e clique no botão Confirmar.
          </p>
          <p class="body-2">
            <b>Atenção: Tem certeza que deseja apagar? Todos seus dados serão apagados.</b>
          </p>

          <v-text-field 
            v-model="dialogs.deleteAccount.input" 
            :label="dialogs.deleteAccount.label" 
            :loading="dialogs.deleteAccount.loading"
            color="secondary"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey"
            text
            @click="dialogs.deleteAccount.toggle = false;"
          >
            Voltar
          </v-btn>
          <v-btn
            color="error"
            :disabled="dialogs.deleteAccount.input!='apagar'"
            :loading="dialogs.deleteAccount.loading"
            @click="deleteAccount"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogs.auth.toggle"
      overlay-opacity=".8"
      transition="slide-y-reverse-transition"
    >
      <v-card color="black">
        <v-card-title>Digite sua Senha</v-card-title>
        <v-card-text class="py-4">
          <p class="text-body-2">
            Por questões de segurança, confirme sua senha para visualizar seus dados de perfil.
          </p>

          <v-text-field 
            v-model="dialogs.auth.input" 
            :label="dialogs.auth.label" 
            :disabled="dialogs.auth.disabled"
            :loading="dialogs.auth.loading"
            :hint="dialogs.auth.hint"
            :append-icon="dialogs.auth.visible ? icons.mdiEye : icons.mdiEyeOff"
            :type="dialogs.auth.visible ? 'text' : 'password'"
            hide-details="auto"
            outlined
            color="primary"
            class="my-2"
            @click:append="dialogs.auth.visible = !dialogs.auth.visible"
            @keyup.enter="getAuth(user.cpf, dialogs.auth.input)"
          />
          <v-btn
            color="secondary"
            text
            small
            :disabled="dialogs.passwordRecovery.loading||dialogs.passwordRecovery.loading"
            :loading="dialogs.passwordRecovery.loading"
            @click="togglePasswordRecovery(true)"
          >
            Esqueci a senha
          </v-btn>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey"
            text
            @click="dialogs.auth.toggle = false;"
            >
            Voltar
          </v-btn>
          <v-spacer />
          <v-btn
            color="secondary"
            text
            :disabled="dialogs.auth.input==''||dialogs.auth.input==null||dialogs.auth.loading"
            :loading="dialogs.auth.loading"
            @click="getAuth(user.cpf, dialogs.auth.input)"
          >
            Acessar dados
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="password.toggle"
      overlay-opacity=".8"
      transition="slide-y-reverse-transition"
    >
      <v-card color="black">
        <v-card-title>Alterar Senha</v-card-title>
        <v-card-text class="py-4">
          <p class="body-1">
            Digite sua nova senha e confirme-a abaixo.
          </p>

          <v-text-field 
            v-model="password.input" 
            :label="password.label" 
            :disabled="password.disabled"
            :loading="password.loading"
            :hint="password.hint"
            :append-icon="password.visible ? icons.mdiEye : icons.mdiEyeOff"
            :type="password.visible ? 'text' : 'password'"
            outlined
            @click:append="password.visible = !password.visible"
            @keyup.enter="updatePassword"
            @change="validatePassword"
            @focus="validatePassword"
            @keyup="validatePassword"
            color="primary"
          />
          <v-text-field 
            v-model="confirmPassword.input" 
            :label="confirmPassword.label" 
            :disabled="confirmPassword.disabled"
            :loading="confirmPassword.loading"
            :hint="confirmPassword.error"
            :append-icon="password.visible ? icons.mdiEye : icons.mdiEyeOff"
            :type="password.visible ? 'text' : 'password'"
            outlined
            @click:append="password.visible = !password.visible"
            @keyup.enter="updatePassword"
            @change="validatePassword"
            @focus="validatePassword"
            @keyup="validatePassword"
            color="primary"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey"
            text
            @click="password.toggle = false;"
          >
            Voltar
          </v-btn>
          <v-btn
            color="secondary"
            text
            :disabled="passwordBtn.disabled"
            @click="updatePassword()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <forgot-password 
      :reset="false" 
      @validated="authUpdated"
    />
  </v-dialog>
</template>

<style>

  .v-dialog--fullscreen > .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 0;
  }

  #profile .v-toolbar {
    background: var(--mobees-black);
    z-index: 2;
  }

  #profile .error {
    color: var(--v-warning-base) !important;
  }

  #profile .content {
    padding: 16px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  #profile .control {
    margin: 4px 0 12px !important;
  }
  #profile .control ~ .hint {
    display: block;
    margin: -24px 0 24px;
    padding: 0 12px;
  }

  .profile-menu.secondary .v-list-item--active {
    color: var(--v-secondary-base) !important;
  }


  hr {
    margin: 8px 0;
  }

  .profile-header .title {
    line-height: 1.25;
  }

  .profile-form .overline {
    margin-top: 16px;
  }

  .profile-img {
    position: relative;
  }

  .profile-img .avatar {
    overflow: initial;
  }

  .profile-img .btn-cancel, .profile-img .btn-ok {
      bottom: 2rem !important;
  }
  .profile-img .btn-cancel {
    left: 2rem;
  }
  .profile-img .btn-ok {
    right: 2rem;
  }

  .profile-form .form-group.completed .section-icon, .profile-form .form-group.pending .section-icon, .profile-form .fixed-icon .v-icon {
    transform: rotate(0) !important;
  }
  .profile-form .form-group.pending .section-icon {
    color: var(--v-warning-base);
  }
  #app .profile-form .error--text {
    color: var(--v-warning-base) !important;
    caret-color: var(--v-warning-base) !important;
  }

  .croppa-container {
    width: 240px;
    height: 240px;
    background: var(--mobees-black);
    border-radius: 50%;
    overflow: hidden;
  }

  .profile-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(38,39,44);
    background: linear-gradient(0deg, var(--v-background-base) 64%, rgba(0,0,0,0) 100%);
  }
  .profile-footer.no-events {
    pointer-events: none;
  }


  .profile-header .btn-upload {
    bottom: 0 !important;
    right: -1.5rem;
  }

  #profile .btn-save {
    bottom: 1rem !important;
  }

  #app #change-password.v-dialog:not(.v-dialog--fullscreen) {
    max-height: initial;
  }

</style>

<script>
  // Icons
  import {
    mdiHelpCircleOutline,
    mdiEye,
    mdiEyeOff,
    mdiClose,
    mdiCheck,
    mdiPencil,
    mdiPencilLock,
    mdiCheckDecagram,
    mdiLock,
    mdiArrowLeftRight,
    mdiDelete,
    mdiAlertCircleOutline
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import dictionary from '@/dictionary.js'
  import { fields, completion, defaults } from '@/services/profile.js'
  import { steps } from '@/services/signup'
  import { sync, get } from 'vuex-pathify'
  import { mask } from 'vue-the-mask'
  import { Scroll } from 'vuetify/lib/directives';
  import _ from 'lodash';
  import axios from 'axios';
  const moment = require('moment');
  // import device from 'mobile-device-detect';
  const md5 = require('md5');
  // import { Plugins } from '@capacitor/core';
  // const { Device } = Plugins;
  import { App } from '@capacitor/app';
  import { Device } from '@capacitor/device';

  export default {
    name: 'Profile',

    props: {
      toggle: {
        type: Boolean,
        default: false,
      }
    },

    components: {
      IconBase: () => import('@/components/IconBase'),
      AvatarDefault: () => import('@/components/icons/AvatarDefault'),
      CompletionProgress: () => import('@/components/CompletionProgress'),
      ForgotPassword: () => import('@/views/login/ForgotPassword'),
      MSelect: () => import('@/components/mSelect'),
      MField: () => import('@/components/mField'),
      Badges: () => import('@/components/badges/BadgesList'),
    },

    data () {
      return {
        api: process.env.VUE_APP_ROOT_API,
        icons: {
          mdiHelpCircleOutline,
          mdiEye,
          mdiEyeOff,
          mdiClose,
          mdiCheck,
          mdiPencil,
          mdiPencilLock,
          mdiCheckDecagram,
          mdiLock,
          mdiArrowLeftRight,
          mdiDelete,
          mdiAlertCircleOutline
        },
        offsetTop: 0,
        toggleUpload: false,
        cropper: {
        },
        now: moment(),
        saveBtn: {
          disabled: false,
          loading: false
        },
        logoutBtn: {
          loading: false
        },
        club_id: {
          toggle: false,
        },
        badge_view: {
          mini: true
        },
        keyboard: false,
        dialogs: {
          auth: {
            toggle: false,
            input: '',
            visible: false,
            loading: false,
            label: 'Confirme sua senha',
            hint: 'Sua senha atual.',
            section: 'personal'
          },
          deleteAccount: {
            toggle: false,
            loading: false,
            disabled: true,
            input: null,
            label: 'Digite apagar para confirmar'
          },
          passwordRecovery: {
            toggle: false,
            loading: false,
            disabled: true,
            input: null,
          },
        },
        password: {
          toggle: false,
          input: '',
          visible: false,
          loading: false,
          disabled: false,
          label: 'Nova senha',
          hint: 'A senha deve ter no mínimo 6 caracteres',
        },
        confirmPassword: {
          input: '',
          visible: false,
          loading: false,
          disabled: false,
          label: 'Nova senha',
          hint: 'A confirmação deve ser igual à nova senha',
        },
        passwordBtn: {
          disabled: false,
          loading: false
        },
        sendBtn: {
          disabled: false,
          loading: false
        },
        timer: {
          controller: null,
          count: 0,
        },
        deviceInfo: null,
        os: null,
        app_version: APP_VERSION,
        app_build: null,
        changed: [],
        sections: {
          personal: false,
          job: false,
          vehicle: false,
          payment: false,
        },
        fields,
        completion,
        steps,
        bankKeyword: '',
        dictionary: { ...dictionary.profile },
        loaded: false,
        ghost: null,
      }
    },

    filters: {
      humanizeExperience (v) {
        const m = moment.duration(v, 'months');
        return v==0 ? 'Selecione:' : m.humanize();
      },
      partnerSince (date) {
        return moment.utc(date).local().from();
      }
    },

    computed: {
      views: sync('app/views'),
      view: sync('app/views@profile'),
      cpf: sync('user/cpf'),
      status: sync('user/status'),
      statusChange: sync('user/statusChange'),
      bankList: sync('user/bankList'),
      profile: sync('user/profile'),
      checkedAddress: sync('user/profile@address.checkedAddress'),
      user: sync('user'),
      auth: sync('user/auth'),
      help: get('help/sections'),
      errors: sync('app/views@help.errors'),
      toast: sync('app/toast'),
      version: sync('app/version'),

      toolbarElevation () {
        return this.offsetTop >= 16 ? 2 : 0;
      },

      shortName () {
        const fullname = this.profile.fullname;
        const names = fullname != null ? fullname.split(' ') : ' ';
        return names[0] + ' ' + names[names.length-1].substr(0,1) + '.';
      },

      recentAuth () {
        return this.ghost!==null||moment().diff(this.auth.timestamp, 'minutes')<=15;
      },

      signup () {
        return this.status=='ACT' ? false : true;
      },

      stepCompletion () {
        const step = this.steps[this.status];
        return _.has(step, 'completion') ? _.round(step.completion*100) : null;
      },

      profileCompletion () {
        const fields = this.fields;
        const completion = _.mapValues(this.completion[this.status], section => {
          section.percent = _.isEmpty(section.required) ? 1 : _.filter(_.map(section.required, (i) => {
            return this.validateField(i);
          }), (f) => f == true).length / section.required.length;
          return section;
        });
        const percent = _.meanBy(_.values(completion), section => section.percent);
        return percent;
      },

      saveable () {
        const changed = this.changed;
        const completion = this.profileCompletion;
        return changed.length>0&&completion==1;
      },

      installed () {
        return typeof mobees != 'undefined';
      },

      networkStatus () {
        return this.isOnline ? 'Online!' : 'Sem conexão de internet 😬'
      },

      requestEvaluationLoading () {
        return this.stepCompletion==100 && this.changed.length>0;
      },

      sectionState () {
        let state;
        const sections = this.completion[this.status];
        state = _.mapValues(sections, (section, s) => {
          const required = this.sectionRequired(s);
          const completed = this.sectionComplete(s);
          const icon = this.sectionIcon(required, completed);
          return {
            required,
            completed,
            icon,
          }
        });
        return state;
      },
    },

    watch: {
      loaded (b) {
        if (b) {
          this.$emit('load', this.profile);
        }
      },
      toggle: {
        immediate: true,
        handler (b) {
          if (b) {
            setTimeout(($) => {
              const state = $.sectionState;
              const pending = _.findKey(state, (section, s) => {
                // console.log('section', s, section);
                return section.required&&!section.completed;
              });
              if (!!pending) {
                _.each($.sections, section => {
                  section = false;
                })
                $.sections[pending] = true;
                console.log('pending', pending);
              }
            }, 500, this);
          }
        }
      },
      sectionState: {
        immediate: true,
        deep: true,
        handler (state) {
          const pending = _.findKey(state, (section, s) => {
            // console.log('section', s, section);
            return section.required&&!section.completed;
          });
          // if (!!pending) this.sections[pending] = true;
          // console.log('pending', pending);
          this.view.pending = this.loaded&&!_.isNil(pending);
        },
      },

      installed: {
        immediate: true,
        handler (installed) {
          // get Device Info
          Device.getInfo().then(device => {
            // console.log('Device:', JSON.stringify(device));
            this.deviceInfo = device;
            this.os = `${device.manufacturer}/${device.model} ${device.operatingSystem} ${device.osVersion}`;
            if (installed) {
              App.getInfo().then(app => {
                // console.log('App:', JSON.stringify(app));
                this.setVersionCode(app.build);
              })
            }
          });
          // Capacitor.Plugins.Mobees.getVersionCode().then(result => {
          //   console.log('### versionCode', result.versionCode);
          //   this.setVersionCode(result.versionCode);
          // });
        },
      },
    },

    methods: {
      ...services,

      // toggleView (b) {
      //   this.view.toggle = b;
      // },

      toggleBadges (event, key) {
        // console.log(this.badge_view.mini, key);
        this.badge_view.mini = this.badge_view.mini ? false : key==null;
      },

      setVersionCode (versionCode) {
        this.app_version = APP_VERSION + '/' + versionCode;
        this.app_build = versionCode;
        this.version = Object.assign({}, { native: versionCode, web: APP_VERSION });
      },

      onFormFocus (b) {
        this.keyboard = b;
      },

      // toggleHelp (b=true, context='profile') {
      //   this.views.help.toggle = !_.isBoolean(b) || b;
      //   this.views.help.context = context;

      //   if (b) this.trackPage('help', {
      //     hash: context,
      //     title: this.help[context].title
      //   });
      // },
      
      // trackPage (page, subroute) {
      //   const route = subroute ? '/'+page+'/'+subroute.hash : '';
      //   const title = subroute ? this.views[page].title + ': ' + subroute.title : this.views[page].title;
      //   // track analytics
      //   this.$gtag.pageview({
      //     page: route,
      //     title: title + ' | Parceiros'
      //   });
      //   console.log(this.views[page].title + ' | Parceiros');
      // },

      onScroll (e) {
        this.offsetTop = e.target.scrollTop;
        if (!this.view.fullscreen&&this.offsetTop>5) {
          this.toggleFullscreen(true);
          this.$vuetify.goTo(0, {
            container: this.$refs.content,
            duration: 500,
            offset: 0,
            easing: 'easeInOutCubic'
          });
        }
      },

      // modelFilter (item, query) {
      //   item = item.toLowerCase();
      //   query = query.toLowerCase();

      //   return item.indexOf(query) > -1;
      // },


      handleCropperInit () {
        this.toggleUpload = false;
      },

      toggleCropper (b) {
        // this.toggleUpload = b;
        if(b) {
          this.$refs.cropper.remove();
          this.$refs.cropper.chooseFile();
        }else{
          this.toggleUpload = false;
          this.$refs.cropper.remove();
        }
      },

      handleNewImage (){
        this.toggleUpload = true;

        // Fix photo orientation
        const meta = this.cropper.getMetadata();
        meta.orientation = 1;
        this.cropper.applyMetadata(meta);
        console.log(meta);
      },

      prepareImgUpload () {
        this.toggleUpload = false;
        this.fields.photo.value = this.$refs.cropper.generateDataUrl('image/jpeg', 0.8);
        this.profileChanged('photo');
        this.updateProfile();
      },

      onFileTypeMismatch () {
        this.toggleToast(
          true,
          'Ops! Formato de imagem inválido',
          5000,
          false,
        );
      },

      onFileSizeExceed () {
        this.toggleToast(
          true,
          'Ops! Tamanho de arquivo muito grande',
          5000,
          false,
        );
      },

      regionFilter (item, query, text) {
        if (_.has(item, 'title')) {
          const normalized = item.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
          return new RegExp(query.toLowerCase()).test(normalized);
        }else{
          return false;
        }
      },

      roundExperience () {
        const xp = this.fields.job.experience.value;
        this.fields.job.experience.value = xp > 10 ? _.round(xp / 12) * 12 : xp;
      },

      updateApps () {
        // update apps_percent
        const apps = this.fields.job.apps.value;
        const options = _.keyBy(this.fields.job.apps.items, 'value');
        const nilled = apps==null||_.isEmpty(apps) ? null : _.some(options, option => {
          const selected = _.indexOf(apps, option.value)>=0;
          return selected&&_.has(option, 'nil')&&option.nil;
        });
        if (nilled) {
          this.$set(this.fields.job.apps, 'value', [_.find(options, ['nil', true]).value]);
        }
        this.fields.job.apps.items = _.map(options, option => {
          const nil = _.has(option, 'nil')&&option.nil;
          return { ...option, disabled: nilled!=null&&(!nil&&nilled||nil&&!nilled) }
        });
        let percent = this.fields.job.apps_percent.value;
        let balance = false;
        const changed = !!apps&&!!percent&&percent.length!=apps.length;
        if (!!apps) {
          if (percent==null||changed) {
            balance = apps.length>1;
            percent = _.map(_.filter(_.clone(this.fields.job.apps.value), (app) => !(_.has(options[app], 'nil')&&options[app].nil)), (app) => {
              return {
                text: options[app].text,
                app,
                value: apps.length==1 ? 100 : null
              }
            });
          }
        }else{
          percent = null;
        }
        this.$set(this.fields.job.apps_percent, 'value', percent);
        this.fields.job.apps_percent.toggle = _.size(percent)>1;
        this.updateAppsPercent(balance, changed&&apps.length==1 ? _.first(apps) : null);
      },

      updateAppsPercent (balance=true, locked=null) {
        let field = this.fields.job.apps_percent.value;
        const controller = this.fields.job.apps_percent;
        const min = _.first(controller.options);
        const total = () => _.sumBy(_.values(field), 'value');
        const equity = (left, ratio, last) => _[last ? 'floor' : 'ceil'](left/min / ratio) * min;
        const unset = () => _.filter(field, ['value', null]).length;
        if (balance&&unset()<=1) {
          field = _.map(field, (selected, i) => {
            const value = selected.value==null ? 
              100-total() : 
              unset()>0||locked.app!=null&&locked.app==selected.app ? 
                selected.value :
                field.length==2 ? equity(100-locked.value, field.length-1, field.length-1==i) : null;
            return { ...selected, value }
          });
          this.$set(this.fields.job.apps_percent, 'value', field);
        }
        this.$nextTick(() => {
          const options = _.map(_.clone(controller.options), (option) => {
            const disabled = unset()<=1 ? false : total()+option>100-(min*(field.length-2));
            return { text: option+'%', value: option, disabled };
          });
          controller.items = options;
          if (locked!=null) this.profileChanged('job.apps_percent');
        });
      },

      sectionIcon (required, completed) {
        return !required||completed ? this.signup ? this.icons.mdiCheck : this.recentAuth ? '$expand' : this.icons.mdiLock : this.icons.mdiAlertCircleOutline;
      },
      
      sectionRequired (section) {
        return this.completion[this.status][section].required.length > 0;
      },

      sectionComplete (section) {
        const status = this.status;
        return status!=null && this.completion[status][section].percent==1;
      },

      setField (field, value) {
        _.set(this.fields, field+'.value', value);
        this.profileChanged(field);
      },

      profileChanged (field) {
        // log change
        const required = _.flatten(_.map(this.completion[this.status], section => section.required)).indexOf(field)>=0;
        const saved = field=='cpf' ? this.cpf : _.get(this.profile, field);
        const value = _.get(this.fields, field).value;
        const valid = this.validateField(field);
        if ((required&&valid||!required)&&(!_.isEqual(saved, _.isString(value) ? this.rawData(value) : value))&&this.changed.indexOf(field)===-1) {
          this.changed.push(field);
          console.log(field, 'changed');
        }else if (required&&!valid&&this.changed.indexOf(field)>=0) {
          this.changed.splice(this.changed.indexOf(field), 1);
        }
        if (this.signup && valid) this.setUpdateTimer(true);
        // process colateral changes
        if (field=='job.apps') {
          this.updateApps();
        }
        this.updateCompletion();
      },

      checkUpdates () {
        if (this.changed.length>0) {
          if (!this.saveBtn.loading) this.updateProfile();
        }else{
          this.setUpdateTimer(false);
        }
      },

      loadingFields (toggle) {
        const fields = this.changed;
        _.each(fields, f => {
          _.get(this.fields, f).loading = toggle;
        })
      },

      profileUpdated (fields) {
        this.changed = _.difference(this.changed, fields);
        console.log(fields, this.changed);
      },

      validateField (f) {
        const fields = this.fields;
        // const profile = { ...this.profile, cpf: this.cpf };
        const field = _.get(fields, f);
        const valid = _.has(field, 'rules') && field.rules.length > 0 ? _.every(field.rules, (rule) => {
          if (rule(field.value, this)!==true) console.log('invalid', f);
          return rule(field.value, this)===true;
        }) : true;

        if (f=='address.zip'&&valid&&!field.disabled) {
          this.getZipAddress(field.value);
        }else if (f=='address.zip'&&!valid&&!field.disabled) {
          this.checkedAddress = false;
        }

        if (_.has(field, 'success')) {
          _.set(this.fields, f + '.success', valid);
        }
        if (_.has(field, 'error')) {
          _.set(this.fields, f + '.error', !valid);
        }
        return valid;
      },

      toggleFields () {
        if (this.signup) {
          const fields = _.flatten(_.map(this.completion[this.status], section => _.union(section.required, section.disabled)));
          const all = _.keys(this.dictionary);
          const hidden = _.xor(all, fields);
          _.each(hidden, key => {
            const field = _.get(this.fields, key);
            if (_.has(field, 'toggle')) field.toggle = false;
          });
          _.each(fields, key => {
            const field = _.get(this.fields, key);
            if (_.has(field, 'toggle')) field.toggle = true;
          }); 
          // if (!(this.status=='ACC'||this.status=='ENR'||this.status=='FDD')) {
          //   _.each(fields, key => {
          //     const field = _.get(this.fields, key);
          //     field.disabled = true;
          //   }); 
          // }
        }
      },

      updateRequiredFields () {
        const sections = this.completion[this.status];

        const required = _.indexOf(['ACC', 'FDB', 'ENR', 'SIG'], this.status)>=0 ? 'required' : 'disabled';
        if (this.profile.vehicle.isOwner==2) {
          sections.vehicle[required].push('vehicle.rental');
          if (this.status=='ACC'||this.status=='FDB') {
            _.pull(sections.vehicle[required], 'vehicle.ownerRelative');
          }else{
            _.pullAll(sections.vehicle[required], ['vehicle.ownerRelative','vehicle.ownerName','vehicle.ownerEmail','vehicle.ownerRg','vehicle.ownerOrg_exp','vehicle.ownerCpf']);
          }
        }else if (this.profile.vehicle.isOwner==0){
          if (this.status=='ACC'||this.status=='FDB') {
            sections.vehicle[required].push('vehicle.ownerRelative');
          }else{
            sections.vehicle[required] = _.union(sections.vehicle[required], ['vehicle.ownerRelative','vehicle.ownerName','vehicle.ownerEmail','vehicle.ownerRg','vehicle.ownerOrg_exp','vehicle.ownerCpf']);
          }
          _.pull(sections.vehicle[required], 'vehicle.rental');
        }
        _.each(sections, (section, s) => {
          _.each(section.inherit, provider => {
            const inherited = this.completion[provider][s].required;
            section.disabled = _.union(section.disabled, inherited);
            section.required = _.union(section.required, inherited);
          });
          _.each(section.disabled, key => {
            const field = _.get(this.fields, key);
            if (_.has(field, 'disabled')) field.disabled = true;
          }); 
          _.each(section.required, key => {
            const field = _.get(this.fields, key);
            field.error = !this.validateField(key);
            if (_.has(field, 'disabled')) field.disabled = false;
          }); 
        });

        this.updateCompletion();
      },

      updateCompletion () {
        this.toggleFields();
        const completion = this.profileCompletion;
        let data = null;
        if (this.status=='ACC') {
          data = { completion };
        }else if (_.indexOf(['ENR', 'SIG'], this.status)>=0) {
          data = { profile: completion };
        }
        this.$emit('step-update', data);
      },

      requestEvaluation () {
        const sections = this.completion[this.status];
        _.each(sections, section => {
          _.map(section.required, (f) => {
            if (f!='cpf') this.profileChanged(f);
          });
        });
        this.$emit('action', 'step1');
      },

      close () {
        // close modal and discard changes
        this.changed = [];
        this.toggleView();
      },

      validatePassword () {
        // validation
        const a = this.password.input.length>=3 ? true : false;
        const b = this.password.input===this.confirmPassword.input ? true : false;
        this.passwordBtn.disabled = !(a&&b);
        return a&&b;
      },

      authUpdated (token) {
        this.auth.token = token;
        this.auth.timestamp = new Date();
        this.dialogs.auth.toggle = false;
      },

      toggleAuth (b, section) {
        if (!this.signup && !this.recentAuth) {
          this.dialogs.auth.toggle = b;
        }
      },

      scrollToSection (section) {
        // setTimeout(vue => {
        //   vue.$vuetify.goTo(vue.$refs[section], {
        //     container: vue.$refs.content,
        //     duration: 500,
        //     offset: 0,
        //     easing: 'easeInOutCubic'
        //   });
        // }, 150, this);
      },

      hasAddress () {
        // const street = 'address.street';
        const city = 'address.city';
        const state = 'address.state';
        const has = _.every([city, state], v => {
          const valid = this.validateField(v);
          return valid;
        })
        return has;
      },

      getZipAddress (zip) {
        const checked = this.checkedAddress;
        if (!this.hasAddress()&&!this.fields.address.zip.loading) {
          this.fields.address.zip.loading = true;
          // axios(`https://nominatim.openstreetmap.org/search?postalcode=${zip}&format=json&addressdetails=1`)
          axios(`https://viacep.com.br/ws/${zip}/json/`)
          .then(response => {
            console.log('/getZipAddress', response);
            if (!_.has(response.data, 'erro')) {
              const street = response.data.logradouro;
              console.log(street, this.profile.address.street);
              if (street!=this.profile.address.street) {
                this.profile.address.street = street;
                this.fields.address.street.disabled = true;
                this.profileChanged('address.street');
              } 
              const city = response.data.localidade;
              console.log(city, this.profile.address.city);
              if (city!=this.profile.address.city) {
                this.profile.address.city = city;
                this.fields.address.city.disabled = true;
                this.profileChanged('address.city');
              } 
              const state = response.data.uf;
              console.log(state, this.profile.address.state);
              if (state!=this.profile.address.state) {
                this.profile.address.state = state;
                this.fields.address.state.disabled = true;
                this.profileChanged('address.state');
              }
              const country = 'Brasil';
              if (country!=this.profile.address.country) {
                this.profile.address.country = 'Brasil';
                this.fields.address.country.disabled = true;
                this.profileChanged('address.country');
              }

              this.checkedAddress = true;
            }else{
              this.disableAddress();
            }
          });
        }else{
          this.disableAddress();
        }
      },

      disableAddress () {
        this.fields.address.street.disabled = true;
        this.fields.address.city.disabled = true;
        this.fields.address.state.disabled = true;
        this.fields.address.country.disabled = true;
      },

      deleteAccount () {
        if (!this.isOnline) return false;
        const token = this.auth.token;
        const cpf = this.rawData(this.cpf);

        this.$api.post('/deleteaccountdriver', {
            cpf: cpf,
            authTk: token,
          })
          .then(response => {
            console.log('deleteAccount => ', response);
            if(response.data.retorno==200){
              // success
              this.toggleToast(
                true,
                'Sua conta foi cancelada com sucesso.',
                5000,
                false,
              );
              this.trackEvent('account', 'deleted');
            }else{
              this.toggleToast(
                true,
                'Não foi possível cancelar sua conta. Tente novamente.',
                5000,
                false,
              );
              this.trackEvent('account', 'delete error');
            }
          })
          .catch(error => {
            this.handleError(error, 'Não foi possível cancelar sua conta. Tente novamente.', true);
          })
          .finally(() => {
            this.getout();
          })
      },

      getAuth (cpf,password) {
        if (!this.isOnline) return false;
        this.dialogs.auth.loading = true;
        cpf = this.rawData(cpf);

        this.$api({
          url: '/authuserdriver',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              cpf: cpf,
              senha: password,
            }
          })
          .then(response => {
            console.log('getAuth => ',response);
            if(response.data[0].retorno===200){
              this.auth = Object.assign({}, this.auth, {
                token: response.data[0].authToken,
                timestamp: new Date(),
                status: true,
              });
              this.trackEvent('profile', 'data access auth');
            }else{
              this.toggleToast(
                true,
                'CPF e/ou senha inválidos. Verifique seus dados.',
                5000,
                false,
              );
              this.trackEvent('profile', 'data access invalid credentials');
            }
          })
          .catch(error => {
            this.handleError(error, null, true);
          })
          .finally(() => {
            this.dialogs.auth.toggle = this.dialogs.auth.loading = false;
          });
      },

      updatePassword () {
        if (!this.isOnline) return false;
        if (this.validatePassword()) {
          this.passwordBtn.loading = true;

          const token = this.auth.token;
          const cpf = this.rawData(this.cpf);
          const password = this.password.input;

          this.$api
            .post('/updatepassworddriver', {
              authTk: token,
              cpf: cpf,
              password: password
            })
            .then(response => {
              console.log('updatePassword => ', response);
              if(response.data==201){
                // success
                this.toggleToast(
                  true,
                  'Senha alterada!',
                  5000,
                  false,
                );
                this.password.toggle = false;
                this.trackEvent('profile', 'updated password');
              }else{
                this.toggleToast(
                  true,
                  'Não foi possível alterar a senha',
                  5000,
                  false,
                );
                this.trackEvent('profile', 'update password error');
              }
              this.password.input = this.confirmPassword.input = '';
            })
            .catch(error => {
              this.handleError(error, 'Não foi possível alterar a senha', true);
            })
            .finally(() => {
              this.passwordBtn.loading = false;
            })
        }
      },

      togglePasswordRecovery (b) {
        this.views.forgotPassword.toggle = b;

        // this.trackPage('forgotPassword');
      },

      processFields () {
        // iterate through changed fields and format accordingly
        let data = {};
        const changes = this.changed;
        for(var f in changes){
          if(changes[f].indexOf('.')>=0){
            const keys = changes[f].split('.');
            const [section, field] = keys;
            switch (changes[f]) {
              case 'rg.issuer':
                data[this.translateNotation(changes[f])] = this.fields[section][field].value.toUpperCase();
                break;
              case 'rg.id':
              case 'vehicle.ownerRg':
              case 'vehicle.ownerCpf':
              case 'address.zip':
                data[this.translateNotation(changes[f])] = this.rawData(this.fields[section][field].value);
                break;
              case 'vehicle.plate':
                data[this.translateNotation(changes[f])] = this.rawData(this.fields[section][field].value).toUpperCase();
                break;
              case 'job.rating':
                data[this.translateNotation(changes[f])] = parseFloat(this.fields[section][field].value);
                break;
              case 'job.apps':
              case 'job.products':
              case 'vehicle.fuel':
                data[this.translateNotation(changes[f])] = this.fields[section][field].value.toString();
                break;
              case 'vehicle.model':
                let model = this.fields.vehicle.model.value;
                if (model=='Outro') {
                  model = 'Outros Outros';
                }
                const combo = model.split(' ');
                data[this.translateNotation('vehicle.brand')] = combo.shift();
                data[this.translateNotation(changes[f])] = combo.join(' ');
                break;
              default:
                data[this.translateNotation(changes[f])] = this.fields[section][field].value;
            }
          }else{
            switch (changes[f]) {
              case 'rg':
              case 'phone':
              case 'cpf':
                  data[this.translateNotation(changes[f])] = this.rawData(this.fields[changes[f]].value);
                break;
              case 'birthdate':
                data[this.translateNotation(changes[f])] = moment(this.fields[changes[f]].value,'DD-MM-YYYY').utc().format();
                break;
              case 'email':
                this.auth.sms = false;
                this.fields.email.hint = '';
              case 'status':
                // PREVENT STATUS UPDATE
                break;
              default:
                data[this.translateNotation(changes[f])] = this.fields[changes[f]].value;
            }
          }
        }
        console.log(data);
        return {
          data: data,
          fields: JSON.parse(JSON.stringify(changes))
        }
      },

      updateProfile () {
        if (!this.isOnline) return false;

        this.saveBtn.loading = true;
        if (this.signup) this.loadingFields(true);

        const processed = this.processFields();

        const token = this.auth.token;
        const cpf = this.rawData(this.cpf);

        this.$api
          .post('/updateprofilefield',{
            authTk: token,
            cpf: cpf,
            data: processed.data
          })
          .then(response => {
            console.log('updateProfile => ', response);
            if(response.data==200){
              // success
              if (!this.signup) {
                this.toggleToast(
                  true,
                  'Dados salvos com sucesso!',
                  5000,
                  false,
                );
              }else{
                this.loadingFields(false);
              }
              this.profileUpdated(processed.fields);
              this.trackEvent('profile', 'updated profile');
            }else{
              if (!this.signup) {
                this.toggleToast(
                  true,
                  'Não foi possível salvar os dados',
                  5000,
                  false,
                );
              }else{
                if (_.isNil(this.errors)) this.errors = [];
                this.errors.push({
                  action: 'updateProfile',
                  status: response.data.retorno
                });
                if (this.errors.length>=5) {
                  this.errors.length = 0;
                  window.location.reload();
                  return false;
                }
              }
              this.trackEvent('profile', 'update profile error');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.loadingFields(false);
            this.saveBtn.loading = false;
          })
      },

      getProfile (onSuccess=null) { 
        this.view.loading = true;

        const token = this.auth.token;
        const cpf = this.rawCPF(this.cpf);

        if (cpf==null||token==null) this.getout();

        this.$api
          .get('/getprofileinfo/'+cpf+'/'+token+'/?format=json')
          .then(response => {
            console.log('getProfileInfo => ',response);
            if(response.data.retorno==200){
              // check if local data is more complete
              const profile = this.setFields(response.data);
              this.status = response.data.status_motorista;
              this.statusChange = response.data.dt_Status;

              this.profile = Object.assign({}, this.profile, _.cloneDeep(profile));
              const fields = this.fields;
              _.each(fields, (field, f) => {
                if (_.has(field, 'value')) {
                  field.value = profile[f];
                }else{
                  const section = field;
                  const s = f;
                  _.each(section, (field, f) => {
                    if (_.has(field, 'value')) {
                      field.value = profile[s][f];
                    }
                  });
                }
              })
              fields.cpf.value = response.data.cpf_motorista;

              this.loaded = true;

              if (this.status=='ACT'&&_.indexOf(profile.job.apps, 'TAXIRIO')>=0) this.fields.job.apps.disabled = true;

              this.updateRequiredFields();
              this.updateApps();

              const pending = _.some(this.sectionState, { required: true, completed: false });
              this.view.pending = pending;

              if (this.hasAddress()&&response.data.cep!=null) this.getZipAddress(response.data.cep);

              const version = response.data.vs_app;
              const os = response.data.vs_so;
              this.profile.os = os;
              this.profile.app_version = this.app_version;
              if (version!=this.app_version||os!=this.os) {
                console.log('app version: ', this.app_version);
                console.log('OS: ', this.os);
                this.setAppVersion(this.app_version, this.os);
              }

              this.checkAppUpdate(response.data.latest_vs_app);

              // get updated GPS permission
              // this.hasLocation();

              // TODO: (!) get updated PUSH permission
              // this.hasPush();
              
              // load list of banks 
              this.getBankList();

              if (onSuccess!=null) onSuccess(response.data);

            }else if(response.data.retorno==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.handleError(response.data.retorno, 'Erro desconhecido. Vamos investigar o que houve! 😊');
            }
          })
          .catch(error => {
            this.view.loader = setTimeout(($) => {
              this.view.loader = null;
              $.getProfile();
            }, 7000, this);
            this.handleError(error);
          })
          .finally(() => {
            this.view.loading = false;
          });
      },

      setFields (data) {
        // var model = data.marca + ' ' + data.modelo;
        // if (_.indexOf(this.fields.vehicle.model.items, model)==-1) {
        //   _.each(this.fields.vehicle.model.items, m => {
        //     if (m.indexOf(model.trim())>=0) {
        //       model = m;
        //       console.log('bingo ', m);
        //       this.profileChanged('vehicle.model');
        //       return false;
        //     }
        //   });
        // }
        
        let profile = {
          id: data.id_motorista,
          partner: data.parceiro,
          partner_id: data.id_parceiro,
          fullname: this.normalize(data.nm_motorista),
          photo: data.foto_motorista,
          gender: data.genero,
          signedupAt: moment.utc(data.dt_criacao).local().format('YYYY-MM-DD').toString(),
          birthdate: moment(data.dt_nasc_motorista).format('DD/MM/YYYY').toString(),
          viewedAt: data.viewedAt,
          email: data.email_motorista,
          phone: data.wpp_motorista,
          cnh: data.cnh_motorista,
          club_id: _.has(data, 'id_parceiro')&&data.id_parceiro!=null ? md5(data.id_parceiro.toString()).substr(0, 10) : null,
          rg: {
            id: data.rg_motorista,
            issuer: data.org_exp_motorista,
          },
          gps: data.gps,
          push: data.notific,
          badges: _.isNil(data.badges) ? null : data.badges,
          inactive: data.inatividade,
          hive: data.hive===null ? defaults.hive : data.hive,
          job: {
            products: _.isNil(data.products_interest)||data.products_interest=='' ? null : data.products_interest.split(','),
            special_projects: data.special_projects_interest,
            apps: _.isNil(data.app_work_percent) ? null : _.isNil(data.app_work)||data.app_work=='' ? null : data.app_work.split(','),
            apps_percent: data.app_work_percent,
            service_model: data.mod_trab,
            fulltime: data.nivel_env,
            region: data.regiao_rodada,
            rating: data.nota_app == null ? null : data.nota_app.toFixed(2),
            journey: data.nr_rodadas,
            experience: data.tempo_motorista,
          },
          buzzer: {
            id: data.id_device,
            code: data.cod_device,
            number: data.nr_patrimonio,
            reviewedAt: data.dt_revisao,
            absences: {
              noShows: _.isNil(data.anotacoes) ? 0 : data.anotacoes.faltas,
              reschedules: _.isNil(data.anotacoes) ? 0 : data.anotacoes.remarcacoes
            }
          },
          address: {
            country: data.pais,
            state: data.uf,
            city: data.cidade,
            zip: data.cep,
            neighborhood: data.bairro,
            street: data.logradouro,
            number: data.numero,
            compl: data.complemento
          },
          vehicle: {
            id: data.id_veiculo,
            parking: data.estacionamento,
            plate: data.placa,
            chassis: data.chassi,
            brand: data.marca,
            model: data.marca + ' ' + data.modelo,
            year: data.ano_fab,
            color: data.cor,
            fuel: _.isNil(data.fuel_type)||data.fuel_type=='' ? null : data.fuel_type.split(','),
            renavam: data.renavam,
            isOwner: data.dono,
            rental: data.nm_locadora,
            ownerName: data.nm_prop,
            ownerEmail: data.email_prop,
            ownerRg: data.rg_prop,
            ownerOrg_exp: data.org_exp_prop,
            ownerCpf: data.cpf_prop,
            ownerRelative: data.parent_prop,
          },
          payment: {
            bank: data.id_banco,
            branch: data.agencia,
            account: data.conta,
            digit: data.dv,
            type: data.tipo_conta,
            compl: data.comp_conta,
          },
        }
        // _.each(this.dictionary, (field) => {
        //   const db = _.get(profile, field);
        //   const local = _.get(this.profile, field);
        //   if ((db==null||db=='')&&(local!=db)) {
        //     _.set(profile, field, local);
        //     this.profileChanged(field);
        //   }
        // });

        profile.vehicle.model = profile.vehicle.model.trim();
        if (profile.vehicle.model=='Outros Outros') {
          profile.vehicle.model = 'Outro';
        }

        return profile;
      },

      getBankList () {
        const cpf = this.rawCPF(this.cpf);
        const token = this.auth.token;

        this.$api
          .get('/getbancos/'+cpf+'/'+token+'/?format=json')
          .then(response => {
            console.log('getBankList => ', response);
            if(response.data.length>0){
              this.bankList = response.data.map(function(item){
                return { id: item.id_banco, code: item.cod_banco, title: item.nm_banco };
              });
            }else{
              // this.toggleToast(
              //   true,
              //   'Erro na consulta de bancos',
              //   5000,
              //   false,
              // );
            }
          })
          .catch(error => {
            this.handleError(error);
          });
      },

      setAppVersion (version, os) {
        const token = this.user.auth.token;
        const cpf = this.rawCPF(this.user.cpf);

        this.$api({
          url: '/setappversion',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              cpf: cpf,
              authTk: token,
              version: version,
              os: os
            }
          })
          .then(response => {
            console.log('setAppVersion => ',response);
            if(response.data.retorno==200){
              localStorage.setItem("app_version", version);
              this.trackEvent('app version', 'updated');
            }else{
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }
          })
          .catch(error => {
            // this.handleError(error);
          })
          .finally(() => {
            
          });
      },

      setUpdateTimer (toggle) {
        if (toggle) {
          if (this.timer.controller==null) {
            this.timer.controller = setInterval(vue => {
              vue.checkUpdates();
            }, 5000, this);
          }else{
            clearInterval(this.timer.controller);
            this.timer.controller = setInterval(vue => {
              vue.checkUpdates();
            }, 5000, this);
          }
        }else{
          clearInterval(this.timer.controller);
          this.timer.controller = null;
        }
      },

      requestGpsPermission () {
        if (this.installed&&!this.profile.gps) {
          // this.fields.gps.loading = true; 
          const cpf = this.rawData(this.cpf);
          const token = this.auth.token;

          Capacitor.Plugins.Mobees.requestGpsPermission().then(() => {
            //Capacitor.Plugins.Permissions.query({ name: "geolocation" }).then((result) => {
            Capacitor.Plugins.Permissions.query({ name: "geolocation" }).then((result) => {
                this.profile.gps = (result.state=='granted');

                this.$api.post('/setgeolocationpermission', {
                  cpf: cpf,
                  authTk: token,
                  permitted: this.profile.gps
                })
                .then(response => {
                  console.log('setGeolocationPermission => ', response);
                  if(response.data.retorno==200){
                    // saved
                    this.trackEvent('geolocation', this.profile.gps ? 'enabled' : 'disabled');
                  }else{
                    this.trackEvent('geolocation', 'set state error');
                  }
                });
                setTimeout(vue => {
                  vue.fields.gps.loading = false; 
                }, 500);
          }).catch(() => {
              setTimeout(vue => {
                vue.fields.gps.loading = false; 
              }, 500);
              // TODO Error Permissions.query
            });
          }).catch(() => {
            // TODO Error MobeesCapacitorPlugin.requestGpsPermission
          });
        }
      },

      // hasLocation () {
      //   if (this.installed) {
      //     const cpf = this.rawData(this.cpf);
      //     const token = this.auth.token;

      //     Capacitor.Plugins.Permissions.query({ name: "geolocation" }).then((result) => {
      //       const enabled = (result.state=='granted');

      //       if(this.profile.gps!=enabled){
      //         this.profile.gps = enabled;

      //         this.$api.post('/setgeolocationpermission', {
      //           cpf: cpf,
      //           authTk: token,
      //           permitted: enabled
      //         })
      //         .then(response => {
      //           console.log('setGeolocationPermission => ', response);
      //           if(response.data.retorno==200){
      //             // saved
      //             this.trackEvent('geolocation', enabled ? 'enabled' : 'disabled');
      //           }else{
      //             this.trackEvent('geolocation', 'set state error');
      //           }
      //         });
      //       }
      //     }).catch(() => {
      //       // TODO handle native access error
      //     });
      //   }
      // },

      // hasPush () {
      //   if (this.installed) {
      //     const cpf = this.rawData(this.cpf);
      //     const token = this.auth.token;

      //     Capacitor.Plugins.Permissions.query({ name: "notifications" }).then((result) => { 
      //       const enabled = (result.state=='granted');

      //       if(this.profile.push!=enabled){
      //         this.profile.push = enabled;
              
      //         this.$api.post('/setpushpermission', {
      //           cpf: cpf,
      //           authTk: token,
      //           permitted: enabled
      //         })
      //         .then(response => {
      //           console.log('setPushPermission => ', response);
      //           if(response.data.retorno==200){
      //             // saved
      //             this.trackEvent('notification', enabled ? 'enabled' : 'disabled');
      //           }else{
      //             this.trackEvent('notification', 'set state error');
      //           }
      //         });
      //       }
      //     }).catch(error => {
      //       // Error from Capacitor plugin
      //       console.log('Error getting notification plugin from Capacitor');
      //     });
      //   }
      // },

      checkAppUpdate (latest) {
        // console.log(latest);
        if (!!this.deviceInfo) {
          const web = this.app_version;
          const native = parseInt(this.app_build);
          const os = this.deviceInfo.operatingSystem.toLowerCase();
          if (this.installed) {
            if (!!native&&_.has(latest, os)&&parseInt(latest[os].force) > native) {
              this.$emit('forceUpdate');
            }else if (!!native&&_.has(latest, os)&&parseInt(latest[os].latest) > native) {
              this.toggleToast(
                true,
                'Atualize a versão do app!',
                15000,
                {
                  toggle: true,
                  text: 'Atualizar',
                  action: this.openAppStore
                },
              );
            }
          }else{
            // PWA force update
          }
        }
      },

      openAppStore () {
        window.location = 'https://play.google.com/store/apps/details?id=br.com.mobees.parceiro';
      },

      outdatedVersion (version) {
        return version.split('.');
      }
    },

    mounted () {
      // this.getProfile();
      // this.updateCompletion();

      this.getStorage('ghost').then((value) => {
        this.ghost = value;
      });

      if (this.auth.sms) this.fields.email.error = true;

      // check app latest version
      // const env = process.env.VUE_APP_MODE=='production' ? '' : '.stg';
      // axios(`https://parceiros${env}.mobees.com.br/manifest.json`).then(response => {
      //   console.log(response);
      // });

      // load cities
      // const c = _.map(cities, city => {
      //   return {
      //     title: city.Nome,
      //     group: 'Outras regiões'
      //   }
      // });
      // console.log('cities', c);
      // this.fields.job.region.items = _.concat(this.fields.job.region.items, c);
    },
    directives: {
      Scroll,
      mask,
    }
  }
</script>
